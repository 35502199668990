import React, { Component } from 'react';
import './Pages.css';
import steini from '../assets/images/steini.jpg';
import FooterContact from '../components/Footer';

class CustomPage extends Component {
    render() {
        const { mainText, testimonials } = this.props;

        return (
            <div className="max-w-6xl mx-auto px-2 sm:px-6 py-20">
                <div className='bg-body py-4 rounded-xl px-4'>
                    <div className='grid grid-cols-1 pages:grid-cols-5 gap-8  items-center'>
                        <div className="col-span-1 md:col-span-1 flex md:justify-center">
                            <img src={steini} alt="Steini" className="w-32 h-32 pages:w-64 pages:h-64 object-cover rounded-lg mx-auto" />
                        </div>
                        <div className="pages:col-span-4">
                            <p className='body-text'>{mainText}</p>
                            <div className='flex justify-center pt-6'>
                                <a href="/contact" className="contact-btn">
                                    Hafa samband
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 pages:grid-cols-2 gap-8 pt-10">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="testimonial-box">
                                <p className="testimonial-text">"{testimonial.text}"</p>
                                <span className="testimonial-author">- {testimonial.author}</span>
                            </div>
                        ))}
                    </div>
                    <div className="pt-10">
                        <FooterContact />
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomPage;
