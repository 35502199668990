import React from 'react';
import { useLocation, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Einstaklings from './pages/Einstaklings';
import Sambands from './pages/Sambands';
import Header from './components/Header'
import Contact from './pages/Contact';
import Umsagnir from './pages/Umsagnir';
import './global.css';

function App() {
  const location = useLocation();
  const needsOpaqueBackground = location.pathname !== '/';

  return (
    <div>
      <div className="fixed-background"></div>
      {needsOpaqueBackground && (
        <div className="fixed inset-0 bg-black bg-opacity-35 z-[-1]"></div>
      )}
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/einstaklings" element={<Einstaklings />} />
        <Route path="/sambands" element={<Sambands />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/umsagnir" element={<Umsagnir />} />
      </Routes>
    </div>
  );
}


export default App;
