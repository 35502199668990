import React from 'react';
import FooterContact from '../components/Footer';

function Umsagnir() {
    const testimonials = [
        {
            text: "Ég leitaði til Steins árið 2019 eftir að hafa heyrt góða hluti um hann. Á þessum tíma var ég kominn á endastöð í lífinu. Mér fannst ekkert ganga upp. Hélst ekki í vinnu, líkamlegt form mitt var vægt til orða tekið lélegt, eynhleypur og einmanna, dagleg grasneysla og byrjaður að loka mig af félagslega. Því fylgdi kvíði og vonleysi. Móðir mín tók skrefið með mínu samþykki og pantaði tíma hjá honum. Mér leið svo illa að allan tímann á meðan ökuferðinni með móður minni stóð ætlaði ég að þykjast hafa farið. En inn fór ég og hitti Stein vikulega í marga mánuði. Það er eitthvað við Stein sem ég get ekki lýst með orðum. Menntun hans og starfsreynsla var eitt en ég held að hans lífsþroski og reynsla og það sem hann var búinn að ganga í gegnum í lífinu hafi hjálpað mér sem best.",
            author: "Árni"
        },
        {
            text: "Ég var þess heiðurs aðnjótandi að vinna með Steini Jónssyni um margra ára skeið. Okkar góða samstarf þróaðist í einstaka vináttu enda ekki annað hægt þegar að blandast saman grín og glens við einlæga manngæsku og virðingu fyrir öðrum. Varð ítrekað vitni af því hvernig Steinn nálgaðist vandamál með opnum huga, öðruvísi og oft nýstárlegum hætti en ávallt af virðingu. Óþreytandi einstaklingur sem gott er að hafa sér við hlið.",
            author: "Hjörtur Fr. Vigfússon, framkvæmdastjóri stafrænnar þróunar hjá Securitas"
        },
        {
            text: "Fyrir 5 árum vorum við komin að því að skilja þegar okkur var bent á að fara í hjónabandsráðgjöf en það var eitthvað sem hafði aldrei hafði komið til tals hjá okkur áður. Okkur fannst báðum þetta vera byrjunin á endanum. Við höfðum eins og margir aðrir gengið í gegnum góða og slæma tíma í okkar hjónabandi. Við vorum bæði stressuð og neikvæð fyrir því að það að fara í hjónabandsráðgjöf því við höfðum einhvern veginn enga trú á því að það myndi hjálpa okkur. En þegar við komum til Steins var hann með mjög þægilega nærveru og okkur leið strax vel í návist hans. Strax eftir fyrsta skipti fengum við von. Eftir nokkur skipti með Steini ákváðum við að láta reyna á hjónabandið í einhvern tíma í viðbót og sjá hvað myndi gerast. Nú tveimur árum seinna er skilnaður ekki í huga okkar og en við förum reglulega í tíma til Steins og höldum áfram að vinna í okkar málum. Við sjáum það núna að skilnaður var ekki lausnin fyrir okkur.",
            author: "D&M"
        },
        {
            text: "Það hafa verið forréttindi að hafa fengið að njóta þess að vera vinnufélagi Steins hjá Píeta samtökunum. Hann gæddi vinnustaðinn lífi sem gaf af sér gleði og hlýju, sem gagnaðist jafnt starfsmönnum sem skjólstæðingum. Steini er afar næmur og er fljótur að átta sig og greina fólk og umhverfi. Með notalegri framkomu og virðingu fyrir viðmælanda nær hann fljótt inn fyrir skrápinn og samtalið fer á flug. Og oftar en ekki er það bara það sem þarf. Í öðrum tilfellum er þörf á að fylgja málum eftir í nýjan farveg og þar þekkir Steini vel til á grundvelli reynslu sinnar. Og ef ég þekki hann rétt lætur hann ekki þar við sitja heldur fylgir sínu fólki eftir í það ferli sem verður fyrir valinu og heldur þéttingsfast um sitt fólk, af allri þeirri ástríðu og tryggð sem hann býr yfir sem manneskja.",
            author: "Kristín Ólafsdóttir, framkvæmdastjóri Píeta samtakanna"
        },
        {
            text: "Við hjónin leituðum til Steins eftir erfiða tíma hjá okkur. Þar sem álagið var búið að vera mikið og hafði það bitnað á hjónabandinu okkar. Við vildum ekki gefast upp en kunnum ekki leiðina áfram. Eftir að hafa verið í nokkur skipti hjá Steini vorum við búin að finna taktinn okkar aftur og höfum við sjaldan verið jafn hamingjusöm.",
            author: "T&G"
        },
        {
            text: "Steini var hjarta Píeta Samtakanna. Hann var fyrsti snertipunktur þeirra sem leituðu til Píeta og tók á móti öllum með fagmannleika og gleði. Það var sannur heiður að fá að starfa með honum og sjá færni hans í mannlegum samskiptum. Hjarta hans slær fyrir þeim sem þurfa aðstoð í lífinu.",
            author: "Guðbjörn Lárus Guðmundsson sálfræðingur, EMDR stofan"
        },
        {
            text: "Ég var búinn að einangra mig frá öllum og fannst ég tengjast fáum eftir að ég missti vinnunna mína. Ég var mjög ruglaður í því hvað ég vildi gera og fann mig í mjög fáu. Kvíðinn hjá mér var farin að aukast og ég reyndi að komast hjá því að hitta fólk. En ein af þeim fáu vinum var voru enn í sambandi við mig bentu mér að fara til Steins í ráðgjöf og athuga hvort ég fengi ekki einhvern bata. Ég hafði samband við hann og fékk að koma í tíma til hans. Eftir að hafa koma nokkrum sinnum og fór mér að líða betur. Steinn er með góða nærveru og gott er að tala við hann, hann á auðvelt með að hlusta og hjálpa manni að finna lausnina.",
            author: "K"
        },
        {
            text: "Ég starfaði með Steina hjá Píeta samtökunum, það var rosalega gott að vinna með honum. Hann er yndislegur í alla staði og mikill mannvinur. Hann mætir fólki með kærleika, virðingu og velvild að leiðarljósi. Allt sem hann tekur sér fyrir hendur gerir hann vel og miklu meira enn það. Ég mun sakna þess að hafa hann hjá Píeta samtökunum en um leið veit ég að hann mun verða frábær og ómissandi á nýjum stað.",
            author: "Lovísa, félags og fjölskylduráðgjafi hjá Píeta samtökunum"
        },
        {
            text: "Eftir erfið andleg veikindi sem reyndu mikið á hjónabandi ákváðum við í sameiningu að leita okkur hjálpar hjá hjónabandsráðgjafa. Það var ekkert erfitt fyrir okkar að ákveða það þar sem búið var að vera mjög erfitt heima vegna veikinda. Við leituðum til Steins þar sem við vorum búin að heyra vel af honum. Hann tók okkur mjög vel og hafði góðan skilning á því sem var búið að ganga á hjá okkur. Við erum á betri stað í dag og er allt uppá við.",
            author: "S&A"
        },
        {
            text: "Steinn Jónsson var einn af fyrstu starfsmönnum Píeta samtakanna og má segja að hann sé ekki bara einn af stofnendum heldur líka í þeim hóp sem mótaði umgjörð samtakanna. Steini er fagmaður fram í fingurgóma, þekking og skilningur hans á þörfum einstaklinga sem þurfa aðstoð er einstök. Reynsla hans, þekking og persónuleiki hefur hjálpað mörgum sem hafa leitað til Píeta samtakanna og fjölskyldum þeirra. Það er bara til einn Steini.",
            author: "Benedikt Þór Guðmundsson, stofnandi og móttökuráðgjafi hjá Píeta samtökunum."
        },
        {
            text: "Reynsla mín af samstarfi við Steina hjá Píeta Samtökunum er bara frábær. Færni hans í mannlegum samskiptum sýndi sig og sannaði þar, í viðkvæmum samtölum við fólk sem leitaði þangað í erfiðri stöðu. Innsæi hans, hlýtt viðmót ásamt húmor þegar við á gerir það að verkum að hann hefur einstakt lag á að ná til fólks og skapa traust í samskiptum.",
            author: "Eva, sálfræðingur hjá Píeta samtökunum"
        },
        {
            text: "Það eru forréttindi að kynnast manni eins og Steina. Steini er einstök persóna. Hann á mjög auðvelt að ná til fólks og hefur mikinn áhuga á fólki. Hann er góður hlustandi og einlægur í samskiptum. Hann hefur mikinn mannskilning er faglegur og sýnir traust. Hann hefur trú á þeim sem hann er í samskiptum við og er hvetjandi. Steini er hress og skemmtilegur og gott að vera í návist hans.",
            author: "Björk Jónsdóttir, fyrrv. form. Píeta samtakanna og skólastjóri Brúarskóla"
        }
    ];

    return (
        <div className="max-w-6xl mx-auto px-2 sm:px-6 py-20">
            <div className='bg-body py-4 rounded-xl px-4'>
                <div className="flex flex-col pages:flex-row justify-between gap-8">
                    <div className="pages:flex-1">
                        {testimonials.filter((_, index) => index % 2 === 0).map((testimonial, index) => (
                            <div key={index} className="testimonial-box mb-8">
                                <p className="testimonial-text">"{testimonial.text}"</p>
                                <span className="testimonial-author">- {testimonial.author}</span>
                            </div>
                        ))}
                    </div>
                    <div className="pages:flex-1">
                        {testimonials.filter((_, index) => index % 2 !== 0).map((testimonial, index) => (
                            <div key={index} className="testimonial-box mb-8">
                                <p className="testimonial-text">"{testimonial.text}"</p>
                                <span className="testimonial-author">- {testimonial.author}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pt-10">
                    <FooterContact />
                </div>
            </div>
        </div>
    );


}


export default Umsagnir