import { React, useState, useEffect } from 'react';
import './LandingPage.css'

function LandingPage() {
    const [isWide, setIsWide] = useState(window.innerWidth > 460);

    useEffect(() => {
        function handleResize() {
            setIsWide(window.innerWidth > 460);
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    console.log(isWide)

    return (
        <div>
            <div className="relative min-h-screen flex items-center justify-center bg-no-repeat bg-cover bg-center bg-fixed">
                <div className="flex h-screen flex-col justify-center sm:pt-56">
                    <div className="max-w-6xl mx-auto px-4">
                        <p className="text-center px-2 italic">"Steinn Jónsson, er margreyndur í mannlegum samskiptum og ráðgjöf. Hann hefur umfangsmikla
                            reynslu af stjórnun og umsjón mannauðsmála auk menntunar frá Ráðgjafaskóla Íslands og
                            Endurmenntun Háskóla Íslands.
                            Steinn hefur áratuga reynslu af að vinna með fólki í vanda og hefur unnið sér frábært orðspor með
                            vinnu sína með ungu fólki (frá 18 ára aldri) og samskiptavanda para/hjóna."</p>
                    </div>
                    <div className="flex flex-col sm:flex-row justify-center items-center gap-4 pt-12 sm:pt-36 px-2 text-center flex-wrap">
                        <a href="/einstaklings" className="btn-custom">
                            Einstaklingsráðgjöf
                        </a>
                        <a href="/sambands" className="btn-custom">
                            {isWide ? 'Sambands- og hjónabandsráðgjöf' : <>Sambands-<br />og hjónabandsráðgjöf</>}
                        </a>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default LandingPage;

