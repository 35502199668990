import React, { useState } from 'react';
import './Pages.css'
import emailjs from '@emailjs/browser';
import CircularProgress from '@mui/material/CircularProgress';


const FormInput = ({ label, name, type, value, onChange, rows, loading }) => {
    const inputField = rows ? (
        <div className="input-wrapper">
            <textarea
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                rows={rows}
                className="form-input"
                disabled={loading}
            />
            {loading && <div className="loading-overlay"></div>}
        </div>
    ) : (
        <div className="input-wrapper">
            <input
                type={type}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                className="form-input"
                disabled={loading}
            />
            {loading && <div className="loading-overlay"></div>}
        </div>
    );

    return (
        <div className="mb-4">
            <label htmlFor={name} className="block font-body text-sm">
                {label}:
            </label>
            {inputField}
        </div>
    );
};

function ContactForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        message: '',
    });
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(formData);
        emailjs.send('service_4h8vtzp', 'template_fvlva7e', formData, {
            publicKey: 'yfuZ9K5li0ohHVjZx'
        })
            .then((result) => {
                setMessage('Skilaboð send');
                setMessageType('success');
                setTimeout(() => setMessage(''), 10000);
                setFormData({
                    from_name: '',
                    from_email: '',
                    message: '',
                });
                setIsLoading(false);
            }, (error) => {
                setMessage('Ekki tókst að senda skilaboð');
                setMessageType('error');
                setTimeout(() => setMessage(''), 10000);
                setIsLoading(false);
            });
    };

    const isFormIncomplete = !formData.from_name || !formData.from_email || !formData.message;

    return (
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
            <FormInput
                label="Nafn"
                name="from_name"
                type="text"
                value={formData.from_name}
                onChange={handleChange}
                loading={isLoading}
            />
            <FormInput
                label="Email"
                name="from_email"
                type="email"
                value={formData.from_email}
                onChange={handleChange}
                loading={isLoading}
            />
            <FormInput
                label="Skilaboð"
                name="message"
                value={formData.message}
                onChange={handleChange}
                loading={isLoading}
                rows="6"
            />
            <button disabled={isFormIncomplete || isLoading} className="submit-btn">
                Senda
                {isLoading && <CircularProgress size={14} color="inherit" style={{ marginLeft: 8 }} />}
            </button>
            {message && (
                <div className="flex justify-center">
                    <div className={messageType === 'success' ? 'success-message' : 'error-message'}>
                        {message}
                    </div>
                </div>
            )}
        </form>
    );
}

export default ContactForm;
