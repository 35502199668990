import React from 'react';
import ContactForm from './ContactForm';
import PhoneIcon from '@heroicons/react/24/outline/PhoneIcon';
import EmailIcon from '@heroicons/react/24/outline/InboxIcon';
import FooterContact from '../components/Footer';

function Contact() {
    return (
        <div className="max-w-xl mx-auto px-2 sm:px-6 py-20">
            <div className='bg-body py-4 rounded-xl px-4'>
                <ContactForm />
                <div className="pt-4">
                    <FooterContact />
                </div>
            </div>
        </div>
    );
}

export default Contact