import React from 'react';
import './Pages.css'
import CustomPage from './CustomPage';


function Sambands() {

    const mainText = "Tilfinningin að elska og vera elskaður af maka sínum er ólýsanleg. En jafnvægið getur verið viðkvæmt og mikilvægt að rétta ójafnvægið af áður en í óefni er komið. Er traustið farið? Eru ágreinings vandamál til staðar? Er sameiginleg ákvörðun að láta allt ganga upp með grímu þar til börnin eru flogin úr hreiðrinu? Stundum er gott að fá hlutlausan aðila með þekkingu og reynslu til aðstoðar við að ná jafnvægi á ný. Er von? Já, það er alltaf von. Það er von ef báðir aðilar hafa löngun til þess að mætast á miðri leið og leggja til vinnuna sem þarf til að finna hamingjuna á ný – eða að öðrum kosti segja skilið hvort við annað með heiðarlegri tilraun til úrbóta að baki."

    const testimonials = [
        {
            text: "Fyrir 5 árum vorum við komin að því að skilja þegar okkur var bent á að fara í hjónabandsráðgjöf en það var eitthvað sem hafði aldrei hafði komið til tals hjá okkur áður. Okkur fannst báðum þetta vera byrjunin á endanum. Við höfðum eins og margir aðrir gengið í gegnum góða og slæma tíma í okkar hjónabandi. Við vorum bæði stressuð og neikvæð fyrir því að það að fara í hjónabandsráðgjöf því við höfðum einhvern veginn enga trú á því að það myndi hjálpa okkur. En þegar við komum til Steins var hann með mjög þægilega nærveru og okkur leið strax vel í návist hans. Strax eftir fyrsta skipti fengum við von. Eftir nokkur skipti með Steini ákváðum við að láta reyna á hjónabandið í einhvern tíma í viðbót og sjá hvað myndi gerast. Nú tveimur árum seinna er skilnaður ekki í huga okkar og en við förum reglulega í tíma til Steins og höldum áfram að vinna í okkar málum. Við sjáum það núna að skilnaður var ekki lausnin fyrir okkur.",
            author: "D&M"
        },
        {
            text: "Við hjónin leituðum til Steins eftir erfiða tíma hjá okkur. Þar sem álagið var búið að vera mikið og hafði það bitnað á hjónabandinu okkar. Við vildum ekki gefast upp en kunnum ekki leiðina áfram. Eftir að hafa verið í nokkur skipti hjá Steini vorum við búin að finna taktinn okkar aftur og höfum við sjaldan verið jafn hamingjusöm.",
            author: "T&G"
        },

    ]
    return <CustomPage mainText={mainText} testimonials={testimonials} />;
}

export default Sambands;