import React from 'react';
import './Pages.css'
import CustomPage from './CustomPage';


function Einstaklings() {

    const mainText = "Ertu ráðalaus? Ráðvillt/ur? Þarftu áttavita? Líður þér illa? Vantar þig hlutlausa, hlýja hlustun og bara pústa? Ég legg til eyru sem heyra hvað þú segir og augu sem sjá hver þú ert. Mætumst á þínum forsendum og tölum saman. Við getum hist alltaf hjá mér eða hjá þér – úti eða inni, hér og þar á svæði sem þú finnur fyrir öryggi. Við tökum svo slaginn saman og ég fylgi þér alla leið í mark."

    const testimonials = [
        {
            text: "Ég leitaði til Steins árið 2019 eftir að hafa heyrt góða hluti um hann. Á þessum tíma var ég kominn á endastöð í lífinu. Mér fannst ekkert ganga upp. Hélst ekki í vinnu, líkamlegt form mitt var vægt til orða tekið lélegt, eynhleypur og einmanna, dagleg grasneysla og byrjaður að loka mig af félagslega. Því fylgdi kvíði og vonleysi. Móðir mín tók skrefið með mínu samþykki og pantaði tíma hjá honum. Mér leið svo illa að allan tímann á meðan ökuferðinni með móður minni stóð ætlaði ég að þykjast hafa farið. En inn fór ég og hitti Stein vikulega í marga mánuði. Það er eitthvað við Stein sem ég get ekki lýst með orðum. Menntun hans og starfsreynsla var eitt en ég held að hans lífsþroski og reynsla og það sem hann var búinn að ganga í gegnum í lífinu hafi hjálpað mér sem best.",
            author: "Árni"
        },
        {
            text: "Ég var búinn að einangra mig frá öllum og fannst ég tengjast fáum eftir að ég missti vinnunna mína. Ég var mjög ruglaður í því hvað ég vildi gera og fann mig í mjög fáu. Kvíðinn hjá mér var farin að aukast og ég reyndi að komast hjá því að hitta fólk. En ein af þeim fáu vinum var voru enn í sambandi við mig bentu mér að fara til Steins í ráðgjöf og athuga hvort ég fengi ekki einhvern bata. Ég hafði samband við hann og fékk að koma í tíma til hans. Eftir að hafa koma nokkrum sinnum og fór mér að líða betur. Steinn er með góða nærveru og gott er að tala við hann, hann á auðvelt með að hlusta og hjálpa manni að finna lausnina.",
            author: "K"
        },

    ]
    return <CustomPage mainText={mainText} testimonials={testimonials} />;
}

export default Einstaklings;