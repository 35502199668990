import React from 'react';
import PhoneIcon from '@heroicons/react/24/outline/PhoneIcon';
import EmailIcon from '@heroicons/react/24/outline/InboxIcon';

function FooterContact() {
    return (
        <>
            <hr />
            <div className="footer-content flex flex-col contact:flex-row justify-between items-center py-4 gap-2">
                <div className="flex-grow text-center font-body text-sm">
                    <a href="mailto:info@hamingjaogvellidan.is" className="footer-link flex justify-center items-center">
                        <div className="icon-container">
                            <EmailIcon className='icon-footer' />
                        </div>
                        <span className="ml-2">info@hamingjaogvellidan.is</span>
                    </a>
                </div>
                <div className="flex-grow text-center font-body text-sm">
                    <a href="tel:+3546928610" className="footer-link flex justify-center items-center">
                        <div className="icon-container">
                            <PhoneIcon className='icon-footer' />
                        </div>
                        <span className="ml-2">692 8610</span>
                    </a>
                </div>
            </div>
        </>
    );
}

export default FooterContact;
