import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Header.css'
import MenuIcon from '@heroicons/react/24/outline/Bars3Icon';
import XIcon from '@heroicons/react/24/outline/XMarkIcon';

const NavLink = ({ href, children }) => {
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <a href={href} className={`nav-links ${isActive ? 'active' : ''}`}>
      {children}
    </a>
  );
};

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const [useBg, setUseBg] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const scrollThreshold = 10;

      if (scrolled > scrollThreshold) {
        setUseBg(true);
      } else {
        setUseBg(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const headerClass = useBg || isMenuOpen ? 'header-bg' : 'header';

  return (
    <header className={headerClass}>
      <div className="max-w-6xl mx-auto px-1 sm:px-4">
        <div className="flex justify-between items-center">
          <div className="flex space-x-4 items-center">
            <div>
              <a href="/" className="title-links">
                <span className="font-bold">Hamingja & Vellíðan</span>
              </a>
            </div>
          </div>
          <div className="hidden header:flex space-x-1 items-center">
            {location.pathname !== '/' && (
              <>
                <NavLink href="/einstaklings">Einstaklingsráðgjöf</NavLink>
                <NavLink href="/sambands">Sambands og hjónabandsráðgjöf</NavLink>
              </>
            )}
            <NavLink href="/umsagnir">Umsagnir</NavLink>
            <NavLink href="/contact">Hafa samband</NavLink>
          </div>
          <div className="header:hidden flex items-center py-4">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <XIcon className="icon" /> : <MenuIcon className="icon" />}
            </button>
          </div>
        </div>
      </div>

      {
        isMenuOpen && (
          <div className='opacity-100 visible header:hidden transition-opacity duration-300 ease-linear'>
            <div className="max-w-6xl mx-auto px-4">
              <a href="/einstaklings" className="nav-links-mobile">- Einstaklingsráðgjöf</a>
              <a href="/sambands" className="nav-links-mobile">- Sambands og hjónabandsráðgjöf</a>
              <a href="/umsagnir" className="nav-links-mobile">- Umsagnir</a>
              <a href="/contact" className="nav-links-mobile">- Hafa samband</a>
            </div>
          </div>
        )
      }

    </header>

  );
};

export default Header;
